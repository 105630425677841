import { ref, uploadBytes, getDownloadURL, deleteObject, listAll } from "firebase/storage";
import { setDoc, deleteDoc, doc, collection, getDocs, query, where } from "firebase/firestore";
import { v4 as uuidv4 } from "uuid";
import { drawOnCanvas } from "./canvasUtils";
import { db } from '../firebaseConfig';


export const handleDownloadClick = async (canvasRef, gridSize, currentUser, storage) => {
    const canvas = canvasRef.current;
    if (!canvas) {
        console.error("Canvas element not found.");
        return;
    }

    const imageUrl = canvas.toDataURL("image/jpeg");
    const blob = await (await fetch(imageUrl)).blob();

    const currentDate = new Date();
    const formattedDate = `${currentDate.getFullYear()}-${(currentDate.getMonth() + 1)
        .toString()
        .padStart(2, '0')}-${currentDate.getDate().toString().padStart(2, '0')}`;
    const fileName = `Nostai_QuiltLayout_${gridSize}_${formattedDate}.jpg`;

    if (currentUser && currentUser.uid) {
        const storageRef = ref(storage, `user_quilts/${currentUser.uid}/${fileName}`);
        try {
            await uploadBytes(storageRef, blob);
            console.log(`Quilt saved to Firebase Storage: user_quilts/${currentUser.uid}/${fileName}`);
        } catch (error) {
            console.error("Error uploading quilt to Firebase Storage:", error);
            return;
        }
    } else {
        console.error("User not authenticated. Unable to save quilt.");
        return;
    }

    const link = document.createElement("a");
    link.href = imageUrl;
    link.download = fileName;
    link.click();
};



export const handleShareClick = async (
    canvasRef,
    setShowModal,
    setShareLink,
    currentUser,
    storage,
    db
) => {
    setShowModal(true);
    setShareLink("");

    const canvas = canvasRef.current;
    if (!canvas) {
        console.error("Canvas not found");
        setShowModal(false);
        return;
    }

    try {
        const imageUrl = canvas.toDataURL("image/jpeg");
        const blob = await (await fetch(imageUrl)).blob();

        const designId = `${currentUser.uid}-${uuidv4()}`;
        const storageRef = ref(storage, `user_quilts/${currentUser.uid}/${designId}.jpg`);
        await uploadBytes(storageRef, blob);
        const publicUrl = await getDownloadURL(storageRef);

        await setDoc(doc(db, "shared_quilts", designId), {
            quiltImageUrl: publicUrl,
            userId: currentUser.uid,
            createdAt: new Date(),
        });

        const shareableLink = `https://nostai.com/c/${designId}`;
        setShareLink(shareableLink);
    } catch (error) {
        console.error("Error sharing quilt:", error);
        setShareLink("Error creating link");
    }
};



export const handleShareAIClick = async (
    layout,
    layoutName,
    setShowModal,
    setShareLink,
    currentUser,
    storage,
    db,
    quiltGrid,
    gridSize,
    logoSrc
) => {
    setShowModal(true);
    setShareLink("");

    // Create a temporary canvas
    const tempCanvas = document.createElement("canvas");
    const adjustedGrid = layout.matrix.flatMap((row) =>
        row.map((pos) => {
            try {
                const [tileRow, tileCol] = pos.split(",").map(Number);
                return quiltGrid[tileRow * gridSize.split("x")[0] + tileCol] || { url: null };
            } catch (error) {
                console.error("Error adjusting grid:", error);
                return null;
            }
        })
    );

    // Draw on the canvas
    drawOnCanvas({ current: tempCanvas }, adjustedGrid, gridSize, logoSrc);

    setTimeout(async () => {
        try {
            const imageUrl = tempCanvas.toDataURL("image/jpeg");
            const blob = await (await fetch(imageUrl)).blob();

            const designId = `${currentUser.uid}-${Date.now()}`;
            const storageRef = ref(storage, `user_quilts/${currentUser.uid}/${designId}.jpg`);
            await uploadBytes(storageRef, blob);
            const publicUrl = await getDownloadURL(storageRef);

            await setDoc(doc(db, "shared_quilts", designId), {
                quiltImageUrl: publicUrl,
                userId: currentUser.uid,
                createdAt: new Date(),
            });

            const shareableLink = `https://nostai.com/c/${designId}`;
            setShareLink(shareableLink);
        } catch (error) {
            console.error("Error sharing AI quilt:", error);
            setShareLink("Error creating link");
        }
    }, 500); // Ensure sufficient delay for the canvas to render
};

//reset
export const confirmReset = async (
    sessionId,
    storage,
    db,
    setUploadedImages,
    setGridSize,
    setGenerated,
    setRequiredImages,
    setShowWarning,
    setQuiltImageUrl,
    setPreviousLayoutType,
    setGenerateDisabled,
    setIsEditUnlocked,
    setQuiltGrid,
    setSessionId,
    setFeedbackSubmitted,
    setRating,
    setIsResetLoading,
    setAIGeneratedLayouts,
    closeResetModal
) => {
    setIsResetLoading(true);
    try {
        // Delete quilt designs from Firebase Storage
        if (sessionId) {
            const storageRef = ref(storage, `quilt_designs/${sessionId}/`);
            const listResult = await listAll(storageRef);
            const deletePromises = listResult.items.map((itemRef) => deleteObject(itemRef));
            await Promise.all(deletePromises);
            console.log(`Deleted all images from Firebase Storage under quilt_designs/${sessionId}/`);
        }

        // Delete user quilts from Firebase Storage
        if (sessionId) {
            const userQuiltsRef = ref(storage, `user_quilts/${sessionId}/`);
            const listResult = await listAll(userQuiltsRef);
            const deletePromises = listResult.items.map((itemRef) => deleteObject(itemRef));
            await Promise.all(deletePromises);
            console.log(`Deleted all quilts from Firebase Storage under user_quilts/${sessionId}`);
        }

        // Delete shared quilts from Firestore
        if (sessionId) {
            const sharedQuiltsRef = collection(db, "shared_quilts");
            const userSharedQuiltsQuery = query(sharedQuiltsRef, where("userId", "==", sessionId));
            const sharedQuiltsSnapshot = await getDocs(userSharedQuiltsQuery);
            
            const deleteSharedQuiltPromises = sharedQuiltsSnapshot.docs.map(async (doc) => {
                // Delete the shared quilt document
                await deleteDoc(doc.ref);
                console.log(`Deleted shared quilt document: ${doc.id}`);
            });
            
            await Promise.all(deleteSharedQuiltPromises);
            console.log(`Deleted all shared quilts for user: ${sessionId}`);
        }

        // Delete known subcollections for customers
        if (sessionId) {
            await deleteSubcollection(`customers/${sessionId}/payments`);
            await deleteSubcollection(`customers/${sessionId}/checkout_sessions`);
        }

        // Delete customer document
        if (sessionId) {
            const customerRef = doc(db, `customers/${sessionId}`);
            await deleteDoc(customerRef);
            console.log(`Deleted customer document: ${sessionId}`);
        }

        // Delete session data
        if (sessionId) {
            const sessionRef = doc(db, "sessions", sessionId);
            await deleteDoc(sessionRef);
            console.log(`Deleted session data from Firestore: ${sessionId}`);
        }

        // Reset all states
        setUploadedImages([]);
        setGridSize("");
        setGenerated(false);
        setRequiredImages(0);
        setShowWarning("");
        setQuiltImageUrl("");
        setPreviousLayoutType("");
        setGenerateDisabled(false);
        setIsEditUnlocked(false);
        setQuiltGrid([]);
        setSessionId(null);
        setFeedbackSubmitted(false);
        setRating(0);
        setAIGeneratedLayouts([]);

        sessionStorage.removeItem("quiltState");
        console.log("Reset everything to default.");
    } catch (error) {
        console.error("Error during reset:", error);
    }
    setIsResetLoading(false);
    closeResetModal();
};

// Function to delete all documents in a specific subcollection
const deleteSubcollection = async (subcollectionPath) => {
    try {
        const subcollectionRef = collection(db, subcollectionPath); // Reference the subcollection
        const querySnapshot = await getDocs(subcollectionRef); // Get all documents in the subcollection
        const deletePromises = querySnapshot.docs.map((doc) => deleteDoc(doc.ref)); // Delete each document
        await Promise.all(deletePromises); // Wait for all deletes to complete
        console.log(`Deleted all documents in subcollection: ${subcollectionPath}`);
    } catch (error) {
        console.error(`Error deleting subcollection ${subcollectionPath}:`, error);
    }
};

