import React from 'react';
import Layout from './Layout';
import TawkChat from './TawkChat';
const AboutUs = () => {
  return (
    <Layout>
    <div className="min-h-screen bg-white py-16 px-6 md:px-16 lg:px-32">
      <div className="container mx-auto">
        {/* Page Heading */}
        <h1 className="text-4xl font-bold text-center text-[#40302e] mb-8">About Us</h1>

        {/* About Us Content */}
        <div className="max-w-4xl mx-auto text-gray-700">
          <p className="text-lg mb-4">
            Nostai is a revolutionary platform that combines the art of quilt-making with cutting-edge
            artificial intelligence. Our mission is to help people preserve their cherished memories in beautiful,
            personalized t-shirt quilts.
          </p>
          <p className="text-lg mb-4">
            Founded by a team of quilting enthusiasts and AI experts, Nostai was born from the desire to
            make custom memory quilts accessible to everyone. We understand that every t-shirt holds a story,
            and our AI-powered platform ensures that these stories are woven together in the most meaningful
            and visually appealing way possible.
          </p>

          {/* Mission Section */}
          <div className="mt-8">
            <h2 className="text-2xl font-semibold mb-4 text-[#40302e]">Our Mission</h2>
            <p className="text-lg">
              At Nostai, our goal is to provide people with an easy-to-use platform that lets them design
              unique t-shirt quilts that preserve their memories. Whether you’re a seasoned quilter or a beginner,
              our AI helps you every step of the way, from designing the layout to choosing the perfect color schemes.
            </p>
          </div>

          {/* Vision Section */}
          <div className="mt-8">
            <h2 className="text-2xl font-semibold mb-4 text-[#40302e]">Our Vision</h2>
            <p className="text-lg">
              We believe in blending creativity with technology to deliver personalized and meaningful experiences.
              Our vision is to become the go-to platform for memory preservation, allowing users to transform
              their t-shirts into something more than just fabric – into a legacy.
            </p>
          </div>

          {/* Founding Story */}
          <div className="mt-8">
            <h2 className="text-2xl font-semibold mb-4 text-[#40302e]">Our Story</h2>
            <p className="text-lg">
              Nostai was created by a group of friends who shared a deep love for quilting and technology.
              After struggling to find the time and skills to create personalized quilts, they envisioned a solution
              that would allow anyone to create beautiful memory quilts without needing years of quilting experience.
              With this idea, Nostai was born – a seamless integration of traditional craftsmanship and
              modern technology.
            </p>
          </div>
        </div>
      </div>
    </div>
    <TawkChat />
    </Layout>
  );
};

export default AboutUs;
