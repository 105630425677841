import React from 'react';
import Layout from './Layout';
import TawkChat from './TawkChat';

const Contact = () => {
  return (
    <Layout>
      <div className="container mx-auto py-10 px-5">
        <h1 className="text-4xl font-bold text-[#40302e] mb-8">Contact Us</h1>
        
        <p className="text-gray-600 mb-4">
          We’d love to hear from you! Whether you have a question about our services, need support, or just want to share your feedback, we’re here to help.
        </p>

        <div className="mb-8">
          <h2 className="text-2xl font-semibold text-[#40302e] mb-4">📧 Email Us</h2>
          <p className="text-gray-600 mb-4">
            For general inquiries, support, or collaboration opportunities, reach out to us at:
            <br />
            <a href="mailto:info@nostai.com" className="text-blue-500 hover:underline">info@nostai.com</a>
          </p>
        </div>

        <div className="mb-8">
          <h2 className="text-2xl font-semibold text-[#40302e] mb-4">📍 Our Location</h2>
          <p className="text-gray-600 mb-4">
            Beaut Group<br />
            #5/1 First Floor, New Thippasandra Market, HAL 3rd Stage,<br />
            Bangalore 560075, India
          </p>
        </div>

        <div className="mb-8">
          <h2 className="text-2xl font-semibold text-[#40302e] mb-4">📞 Contact Number</h2>
          <p className="text-gray-600 mb-4">
            For immediate assistance, please call us at:
            <br />
            <span className="text-gray-800 font-medium">+91 (080) 42055571</span>
          </p>
        </div>

        <div className="mb-8">
          <h2 className="text-2xl font-semibold text-[#40302e] mb-4">🌐 Stay Connected</h2>
          <p className="text-gray-600 mb-4">
            Follow us on our social media channels to stay updated on our latest innovations and projects:
          </p>
          <p className="text-gray-600 mb-4">
            <a 
              href="https://www.linkedin.com/company/beaut-group" 
              className="text-blue-500 hover:underline mr-4" 
              target="_blank" 
              rel="noopener noreferrer"
            >
              LinkedIn
            </a>
            <a 
              href="https://www.instagram.com/sweetrootstore" 
              className="text-blue-500 hover:underline" 
              target="_blank" 
              rel="noopener noreferrer"
            >
              Instagram
            </a>
          </p>
        </div>

        <p className="text-gray-600">
          We look forward to hearing from you!
        </p>
      </div>
      <TawkChat />
    </Layout>
  );
};

export default Contact;
