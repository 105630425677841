import React, { useState } from "react";
import QuiltEditor from "./QuiltEditor";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEdit, faDownload, faShareAlt } from "@fortawesome/free-solid-svg-icons";
import { drawOnCanvas } from "./canvasUtils";
import { handleShareAIClick } from "./quiltActions";
import ShareModal from "./ShareModal";
import { db } from "../firebaseConfig";

const AILayouts = ({ layouts, quiltGrid, gridSize, storage, currentUser, logoSrc }) => {
    const [shareLink, setShareLink] = useState("");
    const [showShareModal, setShowShareModal] = useState(false);
    const [editingIndex, setEditingIndex] = useState(null);
    
    // Parse grid dimensions correctly
    const [columns, rows] = gridSize.split("x").map(Number);
    
    const [editedQuilts, setEditedQuilts] = useState(
        layouts.map((layout) =>
            layout.matrix.flatMap((row) =>
                row.map((pos) => {
                    const [tileRow, tileCol] = pos.split(",").map(Number);
                    // Use columns for correct index calculation
                    return quiltGrid[tileRow * columns + tileCol] || { url: null };
                })
            )
        )
    );

    const handleEditClick = (index) => {
        setEditingIndex(index);
    };

    const handleSaveChanges = (updatedGrid, index) => {
        const newEditedQuilts = [...editedQuilts];
        newEditedQuilts[index] = updatedGrid;
        setEditedQuilts(newEditedQuilts);

        const updatedMatrix = updatedGrid.map((tile) => `${tile.position[0]},${tile.position[1]}`);
        
        // Use rows and columns for correct matrix reconstruction
        layouts[index].matrix = Array.from(
            { length: rows },
            (_, rowIndex) =>
                updatedMatrix.slice(
                    rowIndex * columns,
                    (rowIndex + 1) * columns
                )
        );

        setEditingIndex(null);
    };

    const handleCancelEdit = () => {
        setEditingIndex(null);
    };

    const handleDownload = (layout, layoutName) => {
        const tempCanvas = document.createElement("canvas");
        const adjustedGrid = layout.matrix.flatMap((row) =>
            row.map((pos) => {
                const [tileRow, tileCol] = pos.split(",").map(Number);
                // Use columns for correct index calculation
                return quiltGrid[tileRow * columns + tileCol] || { url: null };
            })
        );
        drawOnCanvas({ current: tempCanvas }, adjustedGrid, gridSize, logoSrc);

        setTimeout(() => {
            const link = document.createElement("a");
            link.download = `${layoutName}.png`;
            link.href = tempCanvas.toDataURL("image/png");
            link.click();
        }, 500);
    };

    return (
        <div className="mt-8 flex flex-col items-center w-full">
            <h2 className="text-lg font-bold mb-4 text-center" style={{ fontFamily: "Playfair Display" }}>
                AI-Generated Quilt Layouts
            </h2>
            <p className="text-sm text-gray-700 mb-4 text-center" style={{ fontFamily: "Playfair Display" }}>
                Nostai uses AI. Review and edit if needed.
            </p>
            {layouts.map((layout, index) => (
                <div
                    key={index}
                    className="bg-gray-100 p-4 rounded-lg shadow-lg w-full sm:w-[500px] md:w-[600px] flex flex-col items-center border-4 border-zinc-300 mb-8"
                >
                    <h3 className="text-xl font-semibold mb-2 text-center" style={{ fontFamily: "Playfair Display" }}>
                        {layout.layout_name}
                    </h3>
                    <p className="text-sm text-gray-700 mb-4 text-center" style={{ fontFamily: "Playfair Display" }}>
                        {layout.description}
                    </p>
    
                    {editingIndex === index ? (
                        <QuiltEditor
                            quiltGrid={editedQuilts[index]}
                            gridSize={gridSize}
                            onSave={(updatedGrid) => handleSaveChanges(updatedGrid, index)}
                            onCancel={handleCancelEdit}
                        />
                    ) : (
                        <>
                            <div className="quilt-container max-w-full overflow-x-auto flex justify-center items-center">
                                <canvas
                                    ref={(canvas) => {
                                        if (canvas && layout?.matrix) {
                                            const adjustedGrid = layout.matrix.flatMap((row) =>
                                                row.map((pos) => {
                                                    const [tileRow, tileCol] = pos.split(",").map(Number);
                                                    return quiltGrid[tileRow * columns + tileCol] || { url: null };
                                                })
                                            );
                                            drawOnCanvas({ current: canvas }, adjustedGrid, gridSize, logoSrc);
                                        }
                                    }}
                                    className="max-w-full max-h-full"
                                ></canvas>
                            </div>
                            <div 
  className="
    flex 
    flex-row 
    items-center 
    gap-4 
    mt-4 
    justify-center 
    overflow-x-auto 
    whitespace-nowrap
  "
>
  <button
    onClick={() => handleEditClick(index)}
    className="flex items-center justify-center gap-2 text-blue-500 hover:text-blue-600 cursor-pointer"
  >
    <FontAwesomeIcon icon={faEdit} className="text-lg" />
    <span>Edit</span>
  </button>

  <button
    onClick={() => handleDownload(layout, layout.layout_name)}
    className="flex items-center justify-center gap-2 text-green-500 hover:text-green-600 cursor-pointer"
  >
    <FontAwesomeIcon icon={faDownload} className="text-lg" />
    <span>Download</span>
  </button>

  <button
    onClick={() =>
      handleShareAIClick(
        layout,
        layout.layout_name,
        setShowShareModal,
        setShareLink,
        currentUser,
        storage,
        db,
        quiltGrid,
        gridSize,
        logoSrc
      )
    }
    className="flex items-center justify-center gap-2 text-gray-500 hover:text-gray-600 cursor-pointer"
  >
    <FontAwesomeIcon icon={faShareAlt} className="text-lg" />
    <span>Share</span>
  </button>
</div>

                        </>
                    )}
                </div>
            ))}
            {showShareModal && <ShareModal shareLink={shareLink} setShowModal={setShowShareModal} />}
        </div>
    );
};

export default AILayouts;