export const drawOnCanvas = (canvasRef, grid, gridSize, logoSrc) => {
  const canvas = canvasRef.current;
  if (!canvas) {
      console.error("Canvas element not found.");
      return;
  }

  if (!Array.isArray(grid)) {
      console.error("Invalid grid format. Expected an array but received:", grid);
      return;
  }

  const ctx = canvas.getContext("2d");
  const [columns, rows] = gridSize.split("x").map(Number);

  const maxWidth = Math.min(window.innerWidth - 50, 600);
  const tileSize = Math.floor(maxWidth / columns);

  const borderSize = 20;
  const textHeight = 40;

  canvas.width = columns * tileSize + 2 * borderSize;
  canvas.height = rows * tileSize + 2 * borderSize + textHeight;

  ctx.clearRect(0, 0, canvas.width, canvas.height);

  ctx.fillStyle = "white";
  ctx.fillRect(0, 0, canvas.width, canvas.height);

  grid.forEach((tile, index) => {
      const img = new Image();
      img.crossOrigin = "anonymous";
      img.src = tile.url;

      img.onload = () => {
          const x = (index % columns) * tileSize + borderSize;
          const y = Math.floor(index / columns) * tileSize + borderSize;
          ctx.drawImage(img, x, y, tileSize, tileSize);
      };

      img.onerror = (error) => console.error("Failed to load image:", error);
  });

  const logo = new Image();
  logo.crossOrigin = "anonymous";
  logo.src = logoSrc;

  logo.onload = () => {
      const logoWidth = 22;
      const logoHeight = 22;

      const text = "Generated with NOSTAi";
      ctx.font = "bold 16px Arial";
      const textWidth = ctx.measureText(text).width;
      const totalWidth = logoWidth + 10 + textWidth;

      const logoX = canvas.width - totalWidth - 10;
      const logoY = canvas.height - textHeight + (textHeight - logoHeight) / 2;

      ctx.drawImage(logo, logoX, logoY, logoWidth, logoHeight);

      const textX = logoX + logoWidth + 10;
      const textY = canvas.height - textHeight / 2 + 6;

      ctx.fillStyle = "black";
      ctx.textAlign = "left";
      ctx.fillText(text, textX, textY);
  };

  logo.onerror = () => {
      ctx.fillStyle = "black";
      ctx.font = "bold 16px Arial";
      ctx.textAlign = "right";
      const textX = canvas.width - 10;
      const textY = canvas.height - textHeight / 2 + 6;
      ctx.fillText("Generated with NOSTAi", textX, textY);
  };
};
