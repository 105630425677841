import React, { useState } from 'react';
import { doc, updateDoc, addDoc, collection } from "firebase/firestore";
import { db } from '../firebaseConfig'; // Import Firestore DB

const FeedbackSection = ({
    quiltImageUrl,
    currentUser,
    setFeedbackSubmitted,
    setShowThankYouMessage,
    feedbackSubmitted,
    showThankYouMessage, // Add this prop
}) => {
    const [rating, setRating] = useState(0);

    const handleSubmitFeedback = async () => {
        if (!currentUser || !currentUser.uid || !currentUser.email) {
            console.error("User not logged in, user ID, or email not available.");
            return;
        }

        try {
            const feedbackData = {
                userId: currentUser.uid,
                email: currentUser.email,
                rating: rating,
                timestamp: new Date(),
            };

            await addDoc(collection(db, "user_feedback"), feedbackData);
            setFeedbackSubmitted(true);
            const sessionRef = doc(db, 'sessions', currentUser.uid);
            await updateDoc(sessionRef, { feedbackSubmitted: true });
            setShowThankYouMessage(true);

            setTimeout(() => {
                setShowThankYouMessage(false);
            }, 5000);
        } catch (error) {
            console.error("Error submitting feedback:", error);
        }
    };

    return (
        <>
            {quiltImageUrl && !feedbackSubmitted && (
                <div className="flex flex-col items-center mt-8 mb-4">
                    <div className="flex space-x-2 mb-2">
                        {[1, 2, 3, 4, 5].map((star) => (
                            <button
                                key={star}
                                onClick={() => setRating(star)}
                                className={`text-2xl ${star <= rating ? 'text-yellow-400' : 'text-gray-300'}`}
                            >
                                ★
                            </button>
                        ))}
                    </div>
                    <button
                        onClick={handleSubmitFeedback}
                        className="bg-blue-500 text-white px-4 py-1 rounded-lg hover:bg-blue-600 mt-2"
                        style={{ fontFamily: "Playfair Display" }}
                    >
                        Submit Feedback
                    </button>
                </div>
            )}
            {showThankYouMessage && (
                <div className="text-green-500 mt-4" style={{ fontFamily: "Playfair Display" }}>
                    Thank you for your feedback!
                </div>
            )}
        </>
    );
};

export default FeedbackSection;
