import React from 'react';
import Layout from './Layout';
import TawkChat from './TawkChat';
import HotjarIntegration from './HotjarIntegration'
const TermsOfService = () => {
  return (
    <Layout>
      <HotjarIntegration />
      
      <div className="container mx-auto py-10 px-5">
        <h1 className="text-4xl font-bold text-[#40302e] mb-8">Terms of Service</h1>
        <p className="text-gray-600 mb-4">Effective Date: 01/11/2024</p>

        <p className="text-gray-600 mb-4">
          Beaut Group (hereinafter referred to as "we," "us," or "our") is a proprietary firm based in Bangalore, India. We are the owner and operator of the website www.nostai.com ("Website"). By accessing or using our website or services ("Services"), you agree to comply with and be bound by the following terms and conditions ("Terms"). Please read them carefully before using our Services. If you do not agree to these Terms, please do not use our Services.
        </p>

        <h2 className="text-2xl font-semibold text-[#40302e] mb-4">1. Use of Services</h2>
        <ul className="list-disc ml-5 text-gray-600 mb-4">
          <li>You are at least 18 years of age or have the legal consent of a parent or guardian.</li>
          <li>You will use the Services only for lawful purposes and in compliance with all applicable laws.</li>
          <li>You will not upload any content that violates third-party rights, including but not limited to intellectual property, privacy, or publicity rights.</li>
        </ul>

        <h2 className="text-2xl font-semibold text-[#40302e] mb-4">2. Account Registration</h2>
        <ul className="list-disc ml-5 text-gray-600 mb-4">
          <li>Provide accurate, current, and complete information during registration.</li>
          <li>Maintain the confidentiality of your account login credentials.</li>
          <li>Notify us immediately of any unauthorized use of your account.</li>
        </ul>
        <p className="text-gray-600 mb-4">
          You are responsible for all activities that occur under your account, whether or not you authorize them.
        </p>

        <h2 className="text-2xl font-semibold text-[#40302e] mb-4">3. User Content and License</h2>
        <p className="text-gray-600 mb-4">
          When you upload images, designs, or any other content ("User Content") to our platform for quilt design, you grant us a non-exclusive, royalty-free, worldwide license to use, reproduce, and display your User Content for the purposes of providing the Services.
        </p>
        <ul className="list-disc ml-5 text-gray-600 mb-4">
          <li>You own the rights to the User Content you upload or have permission from the content owner to upload it.</li>
          <li>Your User Content does not infringe on any third-party rights or violate any applicable laws.</li>
        </ul>
        <p className="text-gray-600 mb-4">
          We reserve the right to remove or disable access to any User Content that we deem to violate these Terms.
        </p>

        <h2 className="text-2xl font-semibold text-[#40302e] mb-4">4. Payments and Refunds</h2>
        <ul className="list-disc ml-5 text-gray-600 mb-4">
          <li><strong>Pricing:</strong> Prices for our Services are displayed on the website and may change at any time.</li>
          <li><strong>Payment Processing:</strong> Payments are processed securely through third-party payment providers. We do not store your payment information.</li>
          <li><strong>Refund Policy:</strong> All sales are final unless otherwise stated. Refunds may be granted at our discretion for exceptional cases such as technical issues that prevent you from using our Services.</li>
        </ul>

        <h2 className="text-2xl font-semibold text-[#40302e] mb-4">5. Intellectual Property</h2>
        <p className="text-gray-600 mb-4">
          All content and materials provided through the Services, including but not limited to text, images, software, and logos, are the intellectual property of Nostai or its licensors. You agree not to:
        </p>
        <ul className="list-disc ml-5 text-gray-600 mb-4">
          <li>Reproduce, distribute, or modify any part of the Services without our prior written permission.</li>
          <li>Use our intellectual property in any way that could harm or infringe upon our rights or the rights of others.</li>
        </ul>

        <h2 className="text-2xl font-semibold text-[#40302e] mb-4">6. Prohibited Activities</h2>
        <ul className="list-disc ml-5 text-gray-600 mb-4">
          <li>Use the Services in any way that could disrupt, damage, or interfere with other users' experience.</li>
          <li>Attempt to reverse-engineer, hack, or gain unauthorized access to our systems.</li>
          <li>Use automated systems (e.g., bots or scrapers) to access our Services without our permission.</li>
        </ul>

        <h2 className="text-2xl font-semibold text-[#40302e] mb-4">7. Termination</h2>
        <p className="text-gray-600 mb-4">
          We reserve the right to suspend or terminate your access to the Services at our discretion if you violate these Terms or engage in prohibited activities. Upon termination, any licenses or rights granted to you under these Terms will immediately cease.
        </p>

        <h2 className="text-2xl font-semibold text-[#40302e] mb-4">8. Disclaimer of Warranties</h2>
        <p className="text-gray-600 mb-4">
          Our Services are provided "as is" and "as available." We make no warranties or representations about the accuracy, completeness, or reliability of the Services. To the fullest extent permitted by law, we disclaim all warranties, whether express or implied, including any implied warranties of merchantability, fitness for a particular purpose, and non-infringement.
        </p>

        <h2 className="text-2xl font-semibold text-[#40302e] mb-4">9. Limitation of Liability</h2>
        <p className="text-gray-600 mb-4">
          To the fullest extent permitted by law, Nostai and its affiliates shall not be liable for any direct, indirect, incidental, special, or consequential damages arising from your use of the Services. This includes but is not limited to damages for loss of profits, data, or goodwill, even if we have been advised of the possibility of such damages.
        </p>

        <h2 className="text-2xl font-semibold text-[#40302e] mb-4">10. Indemnification</h2>
        <p className="text-gray-600 mb-4">
          You agree to indemnify and hold harmless Nostai, its affiliates, officers, employees, and agents from and against any claims, liabilities, damages, losses, and expenses, including reasonable legal fees, arising out of or in any way connected with your use of the Services or violation of these Terms.
        </p>

        <h2 className="text-2xl font-semibold text-[#40302e] mb-4">11. Governing Law</h2>
        <p className="text-gray-600 mb-4">
          These Terms are governed by and construed in accordance with the laws of India, without regard to its conflict of law provisions. Any legal action or proceeding arising out of or relating to these Terms shall be brought exclusively in the courts of India, and you consent to the jurisdiction of such courts.
        </p>

        <h2 className="text-2xl font-semibold text-[#40302e] mb-4">12. Changes to These Terms</h2>
        <p className="text-gray-600 mb-4">
          We reserve the right to modify or update these Terms at any time. Any changes will be posted on this page with an updated "Effective Date." Your continued use of the Services after changes are posted constitutes your acceptance of the revised Terms.
        </p>

        <h2 className="text-2xl font-semibold text-[#40302e] mb-4">13. Contact Us</h2>
        <p className="text-gray-600 mb-4">
          If you have any questions or concerns about these Terms, please contact us at:
        </p>
        <ul className="list-disc ml-5 text-gray-600 mb-4">
          <li>Email: info@nostai.com</li>
          <li>Address: #5/1 First Floor, New Thippasandra Market, HAL 3rd Stage, Bangalore 560075, India</li>
        </ul>
      </div>
      <TawkChat />
    </Layout>
  );
};

export default TermsOfService;
