import React, { useEffect } from 'react';

const TawkChat = () => {
  useEffect(() => {
    // Remove any existing script to prevent duplicates
    const existingScript = document.getElementById('chat-script');
    if (existingScript) {
      existingScript.remove();
    }

    // 1. Create and add the DelightChat script
    const scriptTag = document.createElement('script');
    scriptTag.async = true;
    scriptTag.src = 'https://d2mpatx37cqexb.cloudfront.net/delightchat-whatsapp-widget/embeds/embed.min.js';
    scriptTag.id = 'chat-script';
    document.body.appendChild(scriptTag);

    // 2. Create and add a configuration script for the widget
    const configScript = document.createElement('script');
    configScript.id = 'chat-script-config';

    // Your config object, as provided
    const configCode = `
      var wa_btnSetting = {
        "btnColor": "#c07116",
        "ctaText": "WhatsApp",
        "cornerRadius": 40,
        "marginBottom": 20,
        "marginLeft": 20,
        "marginRight": 20,
        "btnPosition": "right",
        "whatsAppNumber": "919901540581",
        "welcomeMessage": "Hello, I visited your NOSTAi site. Could you please get back to me?",
        "zIndex": 999999,
        "btnColorScheme": "light"
      };
      
      window.onload = () => {
        _waEmbed(wa_btnSetting);
      };
    `;
    configScript.innerHTML = configCode;
    document.body.appendChild(configScript);

    // Cleanup scripts on unmount
    return () => {
      const oldScript = document.getElementById('chat-script');
      if (oldScript) {
        oldScript.remove();
      }
      const oldConfig = document.getElementById('chat-script-config');
      if (oldConfig) {
        oldConfig.remove();
      }
    };
  }, []);

  // If this widget does not require a visible div, return null. 
  // Otherwise, place any necessary container elements here.
  return null;
};

export default TawkChat;
