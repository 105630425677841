import React, { useState } from 'react';
import { FaWhatsapp, FaFacebookF, FaPinterestP, FaInstagram } from "react-icons/fa";

const ShareModal = ({ shareLink, setShowModal }) => {
    const [isCopied, setIsCopied] = useState(false);

    const copyToClipboard = () => {
        navigator.clipboard.writeText(shareLink);
        setIsCopied(true);
        setTimeout(() => setIsCopied(false), 3000);
    };

    const openShareLink = (url) => {
        window.open(url, "_blank", "noopener,noreferrer");
    };

    return (
        <div className="fixed inset-0 bg-black bg-opacity-50 flex justify-center items-center z-50">
            <div className="bg-white rounded-lg p-6 w-full max-w-md shadow-lg">
                <h2 className="text-lg font-medium mb-4" style={{ fontFamily: "Playfair Display" }}>
                    {shareLink ? "Public Link Created" : "Creating Link..."}
                </h2>
                {shareLink ? (
                    <>
                        <p className="text-sm text-gray-700 mb-4" style={{ fontFamily: "Playfair Display" }}>
                            Share your quilt design with this link:
                        </p>
                        <div className="flex items-center mb-4">
                            <input
                                type="text"
                                value={shareLink}
                                readOnly
                                className="border border-gray-300 rounded-l-lg px-3 py-2 flex-1 text-sm"
                            />
                            <button
                                onClick={copyToClipboard}
                                className={`px-4 py-2 rounded-r-lg ${
                                    isCopied
                                        ? "bg-green-500 text-white"
                                        : "bg-blue-500 text-white hover:bg-blue-600"
                                }`}
                            >
                                {isCopied ? "Link Copied" : "Copy Link"}
                            </button>
                        </div>

                        {/* Social Media Share Buttons */}
                        <div className="flex justify-center space-x-4 mt-4">
                            {/* WhatsApp */}
                            <button
                                onClick={() =>
                                    openShareLink(
                                        `https://wa.me/?text=${encodeURIComponent(
                                            `Check out this quilt design: ${shareLink}`
                                        )}`
                                    )
                                }
                                className="text-green-500 hover:text-green-600 text-2xl"
                                title="Share on WhatsApp"
                            >
                                <FaWhatsapp />
                            </button>
                            {/* Facebook */}
                            <button
                                onClick={() =>
                                    openShareLink(
                                        `https://www.facebook.com/sharer/sharer.php?u=${encodeURIComponent(
                                            shareLink
                                        )}`
                                    )
                                }
                                className="text-blue-600 hover:text-blue-700 text-2xl"
                                title="Share on Facebook"
                            >
                                <FaFacebookF />
                            </button>
                            {/* Pinterest */}
                            <button
                                onClick={() =>
                                    openShareLink(
                                        `https://pinterest.com/pin/create/button/?url=${encodeURIComponent(
                                            shareLink
                                        )}&description=${encodeURIComponent(
                                            "Check out this amazing quilt design!"
                                        )}`
                                    )
                                }
                                className="text-red-500 hover:text-red-600 text-2xl"
                                title="Share on Pinterest"
                            >
                                <FaPinterestP />
                            </button>
                            {/* Instagram */}
                            <button
                                onClick={() =>
                                    alert("Instagram does not support direct link sharing. Please copy the link!")
                                }
                                className="text-pink-500 hover:text-pink-600 text-2xl"
                                title="Share on Instagram"
                            >
                                <FaInstagram />
                            </button>
                        </div>
                    </>
                ) : (
                    <p className="text-sm text-gray-700 mb-4" style={{ fontFamily: "Playfair Display" }}>
                        Please wait while your link is being generated...
                    </p>
                )}
                <div className="flex justify-end">
                    <button
                        onClick={() => setShowModal(false)}
                        className="bg-gray-300 px-4 py-2 rounded hover:bg-gray-400"
                        style={{ fontFamily: "Playfair Display" }}
                    >
                        Close
                    </button>
                </div>
            </div>
        </div>
    );
};

export default ShareModal;
