const priceMapping = {
    '4x4': 'price_1QcVq3SGC3dfhjBhLxcsIyXJ', //4
    '4x5': 'price_1QcVq3SGC3dfhjBhRIYWItqs', //5
    '4x6': 'price_1QcVq3SGC3dfhjBhK0mzWRqx', //6
    '4x7': 'price_1QcVq3SGC3dfhjBh8a5T2M4a', //7
    '5x5': 'price_1QcVq3SGC3dfhjBhK0mzWRqx',//6
    '5x6': 'price_1QcVq3SGC3dfhjBh8a5T2M4a',//7
    '5x7': 'price_1QcVq3SGC3dfhjBhfZBW5EWH',//8
    '6x6': 'price_1QcVq3SGC3dfhjBhfZBW5EWH',//8
    '6x7': 'price_1QcVq3SGC3dfhjBhaKQVf5bF',//9
    '7x7': 'price_1QcVq3SGC3dfhjBhZ9pjtLMp',//10
    '7x8': 'price_1QcVq3SGC3dfhjBhZ9pjtLMp',//10
    '8x8': 'price_1QcVq3SGC3dfhjBhZ9pjtLMp',//10
};

export default priceMapping;
