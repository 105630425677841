import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { getDoc, doc } from "firebase/firestore";
import { db } from "../firebaseConfig";
import HotjarIntegration from './HotjarIntegration';
const ShareableQuiltPage = () => {
    const { designId } = useParams();
    const [quiltImageUrl, setQuiltImageUrl] = useState(null);
    const [loading, setLoading] = useState(true);

    useEffect(() => {
        const fetchDesign = async () => {
            try {
                console.log("Fetching design with ID:", designId);
                const designRef = doc(db, "shared_quilts", designId);
                const designSnap = await getDoc(designRef);

                if (designSnap.exists()) {
                    console.log("Design found:", designSnap.data());
                    setQuiltImageUrl(designSnap.data().quiltImageUrl);
                } else {
                    console.error("Design not found in Firestore.");
                    setQuiltImageUrl(null);
                }
            } catch (error) {
                console.error("Error fetching design:", error);
            } finally {
                setLoading(false);
            }
        };

        fetchDesign();
    }, [designId]);

    const handleDownload = async () => {
        if (!quiltImageUrl) {
            console.error("Quilt image URL is missing.");
            return;
        }

        try {
            const response = await fetch(quiltImageUrl);
            const blob = await response.blob();
            const link = document.createElement("a");
            link.href = URL.createObjectURL(blob);
            link.download = "quilt-design.jpg";
            link.click();
            URL.revokeObjectURL(link.href);
        } catch (error) {
            console.error("Failed to download image:", error);
        }
    };

    if (loading) {
        return (
            <div className="flex items-center justify-center min-h-screen bg-[#F6E8E0] text-gray-700">
                Loading design...
            </div>
        );
    }

    return (
        <div className="flex flex-col items-center justify-center min-h-screen bg-[#F6E8E0] p-4">
            <HotjarIntegration />
            {quiltImageUrl ? (
                <>
                    <img
                        src={quiltImageUrl}
                        alt="Quilt Design"
                        className="max-w-full max-h-[80vh] border-4 border-gray-300 rounded-lg shadow-lg"
                    />
                    <button
                        onClick={handleDownload}
                        className="mt-6 bg-blue-500 text-white px-6 py-3 rounded-lg hover:bg-blue-600 transition-all"
                    >
                        Download Design
                    </button>
                </>
            ) : (
                <div className="text-gray-700 text-lg">Design not found</div>
            )}
        </div>
    );
};

export default ShareableQuiltPage;
