import React from 'react';
import Layout from './Layout';
import HotjarIntegration from './HotjarIntegration'
const PrivacyPolicy = () => {
  return (
    <Layout>
      <div className="container mx-auto py-10 px-5">
      <HotjarIntegration />
        <h1 className="text-4xl font-bold text-[#40302e] mb-8">Privacy Policy</h1>
        <p className="text-gray-600 mb-4">Effective Date: 01/11/2024</p>

        <p className="text-gray-600 mb-4">
          Beaut Group (hereinafter referred to as "we," "us," or "our") is a proprietary firm based in Bangalore, India. We are the owner and operator of the website www.nostai.com ("Website"). This Privacy Policy explains how we collect, use, and share your information when you use our website and services ("Services"). By using the Services, you agree to the terms outlined in this Privacy Policy.
        </p>

        <h2 className="text-2xl font-semibold text-[#40302e] mb-4">1. Information We Collect</h2>
        <ul className="list-disc ml-5 text-gray-600 mb-4">
          <li><strong>Personal Information:</strong> When you register or use our Services, we may collect personal information such as your name, email address, and payment details.</li>
          <li><strong>Images and Uploaded Content:</strong> When you use our AI quilt design feature, you may upload images of clothing (e.g., t-shirts) that will be processed by our system to generate quilt designs.</li>
          <li><strong>Automatically Collected Information:</strong> We automatically collect information such as your IP address, browser type, and usage data when you interact with our website.</li>
        </ul>

        <h2 className="text-2xl font-semibold text-[#40302e] mb-4">2. How We Use Your Information</h2>
        <ul className="list-disc ml-5 text-gray-600 mb-4">
          <li><strong>To Provide and Improve Services:</strong> Your information helps us design custom quilt patterns based on your uploads and preferences.</li>
          <li><strong>To Communicate With You:</strong> We may use your email to send notifications, updates, or respond to your inquiries.</li>
          <li><strong>To Process Payments:</strong> Your payment information is used solely for processing transactions and is not stored on our servers.</li>
          <li><strong>For Analytics:</strong> We analyze usage data to improve and enhance our Services.</li>
        </ul>

        <h2 className="text-2xl font-semibold text-[#40302e] mb-4">3. Sharing of Information</h2>
        <p className="text-gray-600 mb-4">We do not sell your personal information to third parties. However, we may share your information with:</p>
        <ul className="list-disc ml-5 text-gray-600 mb-4">
          <li><strong>Service Providers:</strong> Third-party providers who assist us in delivering the Services, such as payment processors or hosting providers.</li>
          <li><strong>Legal Requirements:</strong> If required by law or legal process, we may disclose your information to comply with legal obligations or protect our rights and safety.</li>
        </ul>

        <h2 className="text-2xl font-semibold text-[#40302e] mb-4">4. Security</h2>
        <p className="text-gray-600 mb-4">
          We implement appropriate technical and organizational measures to protect your personal information. However, no system is 100% secure, and we cannot guarantee absolute security.
        </p>

        <h2 className="text-2xl font-semibold text-[#40302e] mb-4">5. Your Choices</h2>
        <ul className="list-disc ml-5 text-gray-600 mb-4">
          <li><strong>Access and Update Your Information:</strong> You can review and update your personal information by accessing your account settings.</li>
          <li><strong>Delete Your Data:</strong> You can request the deletion of your account and associated data by contacting us.</li>
          <li><strong>Opt-Out of Communications:</strong> You can unsubscribe from our promotional emails by following the instructions in the email.</li>
        </ul>

        <h2 className="text-2xl font-semibold text-[#40302e] mb-4">6. Children's Privacy</h2>
        <p className="text-gray-600 mb-4">
          Our Services are not intended for individuals under the age of 13. We do not knowingly collect personal information from children under 13. If you believe a child has provided us with personal information, please contact us, and we will delete the information.
        </p>

        <h2 className="text-2xl font-semibold text-[#40302e] mb-4">7. International Users</h2>
        <p className="text-gray-600 mb-4">
          If you are accessing our Services from outside India, please be aware that your information may be transferred and processed in countries that may not have the same data protection laws as your jurisdiction.
        </p>

        <h2 className="text-2xl font-semibold text-[#40302e] mb-4">8. Changes to This Privacy Policy</h2>
        <p className="text-gray-600 mb-4">
          We may update this Privacy Policy from time to time. Any changes will be posted on this page with an updated "Effective Date." Your continued use of the Services after changes are posted signifies your acceptance of the updated policy.
        </p>

        <h2 className="text-2xl font-semibold text-[#40302e] mb-4">9. Contact Us</h2>
        <p className="text-gray-600 mb-4">
          If you have any questions or concerns about this Privacy Policy, please contact us at:
        </p>
        <ul className="list-disc ml-5 text-gray-600 mb-4">
          <li>Email: info@nostai.com</li>
          <li>Address: #5/1 First Floor, New Thippasandra Market, HAL 3rd Stage, Bangalore 560075, India</li>
        </ul>
      </div>
    </Layout>
  );
};

export default PrivacyPolicy;
