import React from 'react';
import sweetroot from '../Assets/sweetroot.jpeg'; // Adjust paths as necessary
import memorystitch from '../Assets/memorystitch.png';
import project from '../Assets/projectrepat.jpeg';

const QuilterOptions = () => {
    return (
        <div className="mt-8 bg-gray-100 p-4 rounded-lg shadow-lg">
            <p
                className="text-xl font-bold mb-4 text-center"
                style={{ fontFamily: "Playfair Display" }}
            >
                Connect with a Quilter
            </p>
            {/* Options for Indian Users */}
            <div className="mb-8">
                <p className="text-lg font-semibold mb-2 text-center">For Indian Users</p>
                <div className="flex flex-col sm:flex-row items-center sm:items-stretch space-y-4 sm:space-y-0 sm:space-x-4 p-4 bg-white rounded-lg shadow max-w-md mx-auto">
                    <img
                        src={sweetroot}
                        alt="Sweet Root Logo"
                        className="w-12 h-12 sm:w-16 sm:h-16 rounded-full mx-auto sm:mx-0"
                    />
                    <div className="text-center sm:text-left flex-1">
                        <p className="text-lg font-medium">Sweet Root Memory Quilter</p>
                        <p className="text-sm text-gray-600">Trusted partner for Indian users</p>
                    </div>
                    <button
                        className="w-full sm:w-auto bg-blue-500 text-white px-4 py-2 sm:px-3 sm:py-1 text-sm sm:text-base rounded-lg hover:bg-blue-600"
                        onClick={() =>
                            window.open("https://www.sweetroot.in/quilt-t-shirt-form", "_blank")
                        }
                    >
                        Get Started
                    </button>
                </div>
            </div>
            {/* Options for USA Users */}
            <div>
                <p className="text-lg font-semibold mb-2 text-center">For USA Users</p>
                {/* MemoryStitch Option */}
                <div className="flex flex-col sm:flex-row items-center sm:items-stretch space-y-4 sm:space-y-0 sm:space-x-4 p-4 bg-white rounded-lg shadow max-w-md mx-auto mb-4">
                    <img
                        src={memorystitch}
                        alt="MemoryStitch Logo"
                        className="w-12 h-12 sm:w-16 sm:h-16 rounded-full mx-auto sm:mx-0"
                    />
                    <div className="text-center sm:text-left flex-1">
                        <p className="text-lg font-medium">MemoryStitch</p>
                    </div>
                    <button
                        className="w-full sm:w-auto bg-blue-500 text-white px-4 py-2 sm:px-3 sm:py-1 text-sm sm:text-base rounded-lg hover:bg-blue-600"
                        onClick={() =>
                            window.open(
                                "https://memorystitch.refr.cc/default/u/nostai?s=sp&t=cp",
                                "_blank"
                            )
                        }
                    >
                        Get Started
                    </button>
                </div>
                {/* Project Repat Option */}
                <div className="flex flex-col sm:flex-row items-center sm:items-stretch space-y-4 sm:space-y-0 sm:space-x-4 p-4 bg-white rounded-lg shadow max-w-md mx-auto">
                    <img
                        src={project}
                        alt="Project Repat Logo"
                        className="w-12 h-12 sm:w-16 sm:h-16 rounded-full mx-auto sm:mx-0"
                    />
                    <div className="text-center sm:text-left flex-1">
                        <p className="text-lg font-medium">Project Repat</p>
                    </div>
                    <button
                        className="w-full sm:w-auto bg-blue-500 text-white px-4 py-2 sm:px-3 sm:py-1 text-sm sm:text-base rounded-lg hover:bg-blue-600"
                        onClick={() =>
                            window.open("https://www.projectrepat.com/amb?a=DRdbHRcP", "_blank")
                        }
                    >
                        Get Started
                    </button>
                </div>
            </div>
        </div>
    );
};

export default QuilterOptions;
